import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { IssueCreateAiFeedback } from './IssueCreateAiFeedback';
import { useIssueCreateSidePanelContext } from './providers/IssueCreateSidePanelContextProvider';
import { AiSubFooter } from './AiSubFooter';

const footerContainerStyle = xcss({
	backgroundColor: 'elevation.surface.sunken',
});

const SINGLE_ISSUE_CREATE_FEEDBACK_ENTRYPOINT_ID = '5dd040e8-af22-4ef1-803e-136f659a1092';

export const IssueCreateSidePanelFooter = () => {
	const [{ contentId }] = useIssueCreateSidePanelContext();
	return (
		<Stack xcss={footerContainerStyle}>
			<IssueCreateAiFeedback
				contentId={contentId}
				entrypointId={SINGLE_ISSUE_CREATE_FEEDBACK_ENTRYPOINT_ID}
			/>
			<AiSubFooter />
		</Stack>
	);
};
