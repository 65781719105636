import { useCallback, useMemo, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';

import type { WorkSuggestion, WorkSuggestionWithLocalId } from '../../types';

export const useSuggestions = () => {
	const [hiddenSuggestionIds, setHiddenSuggestionIds] = useState<Set<string>>(new Set());
	const [fullSuggestionList, setFullSuggestionList] = useState<WorkSuggestionWithLocalId[]>([]);
	// used to avoid calling `setSuggestions` too many times during streaming - could break devtools
	const streamedIssueCount = useRef(0);

	const suggestions = useMemo(
		() => fullSuggestionList.filter((suggestion) => !hiddenSuggestionIds.has(suggestion.localId)),
		[fullSuggestionList, hiddenSuggestionIds],
	);

	const hideSuggestion = useCallback((id: string) => {
		setHiddenSuggestionIds((set) => new Set(set).add(id));
	}, []);

	const reset = useCallback(() => {
		setFullSuggestionList([]);
		setHiddenSuggestionIds(new Set());
		streamedIssueCount.current = 0;
	}, []);

	const addSuggestion = useCallback((suggestion: WorkSuggestion) => {
		setFullSuggestionList((suggestions) => [...suggestions, { ...suggestion, localId: uuid() }]);
	}, []);

	const updateSuggestions = useCallback(
		(newSuggestionList: WorkSuggestion[]) => {
			while (streamedIssueCount.current < newSuggestionList.length) {
				addSuggestion(newSuggestionList[streamedIssueCount.current]);
				streamedIssueCount.current++;
			}
		},
		[addSuggestion],
	);

	return {
		fullSuggestionList,
		suggestions,
		hideSuggestion,
		updateSuggestions,
		reset,
	};
};
