import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import {
	ConvoAIResponseState,
	type ConvoAIStreamingState,
	getDefaultConvoAIStreamingState,
} from '../../models/ConvoAIStreamingState';

import { type ConvoAIRequestConfig } from './createConvoAIRequestConfig';
import { readStream } from './readConvoAIStream';

export function makeConvoAIStreamRequest(
	requestConfig: ConvoAIRequestConfig,
): BehaviorSubject<ConvoAIStreamingState> {
	const behaviorSubject = new BehaviorSubject<ConvoAIStreamingState>(
		getDefaultConvoAIStreamingState(),
	);

	fetch(requestConfig.url, {
		credentials: 'include',
		...requestConfig.requestInitParams,
	})
		.then((response) => readStream(response))
		.then(async (stream) => {
			let bufferContent = '';
			let answerChunkIndex = 0;
			for await (const chunk of stream) {
				const state = behaviorSubject.getValue();
				switch (chunk.type) {
					case 'ANSWER_PART':
						bufferContent += chunk.message.content;
						behaviorSubject.next({
							...state,
							responseState: ConvoAIResponseState.AnswerPart,
							content: bufferContent,
							answerChunkIndex,
						});
						answerChunkIndex += 1;
						break;
					case 'CHANNEL_ID':
						const channelId = chunk.message.content;
						behaviorSubject.next({
							...state,
							responseState: ConvoAIResponseState.ChannelId,
							content: channelId,
							answerChunkIndex: undefined,
						});
						break;
					case 'FINAL_RESPONSE':
						bufferContent = chunk.message.content;
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							responseState: ConvoAIResponseState.FinalResponse,
							content: bufferContent,
						});
						break;
					case 'FOLLOW_UP_QUERIES':
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							followUpContent: chunk.message.follow_ups ?? [],
							responseState: ConvoAIResponseState.FollowUp,
						});
						break;
					case 'ERROR': {
						behaviorSubject.next({
							...state,
							answerChunkIndex: undefined,
							responseState: ConvoAIResponseState.Error,
							error: { ...chunk },
						});
						break;
					}
				}
			}
		})
		.finally(() => {
			behaviorSubject.complete();
		});

	return behaviorSubject;
}
