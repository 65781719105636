// Exported through entry point /src/utils.
/**
 * Used as the identifier for container on the consumer side.
 *
 * Example:
 * ```
 * <div id={CONVERSATION_ASSISTANT_CONTAINER_ID}>
 *  <ConversationAssistant />
 * </div>
 * ```
 */
export const CONVERSATION_ASSISTANT_CONTAINER_ID = 'ai-mate-container';

/**
 * Default width for the Conversation Assistant container in pixels
 */
export const CONVERSATION_ASSISTANT_CONTAINER_WIDTH = 439; // px

/**
 * Minimum supported width for the Conversation Assistant container in pixels
 */
export const CONVERSATION_ASSISTANT_MINIMUM_WIDTH = 439; // px

/**
 * Minimum supported height for the Conversation Assistant container in pixels
 */
export const CONVERSATION_ASSISTANT_MINIMUM_HEIGHT = 350; // px

/**
 * For end user messages, like errors, warnings, etc.
 */
export const CONVERSATION_ASSISTANT_BRAND_NAME = 'Chat';
