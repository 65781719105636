import { createContext } from 'react';

import { type ExperienceTrackerAPI } from '../ExperienceTracker';

export const ExperienceTrackerContext = createContext<ExperienceTrackerAPI>({
	abort() {},
	fail() {},
	start() {},
	succeed() {},
	stopOnError() {},
	subscribe() {
		return () => {};
	},
});
