import { useCallback, useEffect, useState } from 'react';

import type { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

export function useObservableEffect<TOutput, TInputs extends Readonly<any[]>>(
	onObservableInit: (input$: Observable<[...TInputs]>) => Observable<TOutput>,
	inputs: [...TInputs],
): [(nextState: [...TInputs]) => void] {
	const [inputSubject$] = useState(() => new Subject<[...TInputs]>());
	const [observable$] = useState(() => onObservableInit(inputSubject$));

	useEffect(() => {
		const subscription$ = observable$.subscribe();

		return () => {
			subscription$.unsubscribe();
		};
	}, [observable$]);

	const callback = useCallback(
		(nextState: [...TInputs]) => inputSubject$.next(nextState),
		[inputSubject$],
	);

	useEffect(() => {
		inputSubject$.next(inputs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, inputs);

	return [callback];
}
