import { useEffect } from 'react';
import {
	JIRA_EMBED_ISSUE_CREATE_CLOSE,
	JIRA_EMBED_ISSUE_CREATE_PROJECT_SELECTED,
	JIRA_EMBED_ISSUE_CREATE_READY,
	JIRA_EMBED_ISSUE_CREATE_SITE_SELECTED,
} from '../messages';
import { useIssueCreateSidePanelContext } from '../providers/IssueCreateSidePanelContextProvider';
import { JiraProjectSelectionPayload } from '../__types__/JiraProjectSelectionPayload';

export const useMessageListener = () => {
	const [
		{ currentSiteSelection },
		{
			setCurrentSiteSelection,
			setCurrentProjectSelection,
			setAiIssueCreationEnablement,
			setIsGicIframeReady,
		},
	] = useIssueCreateSidePanelContext();
	useEffect(() => {
		const messageHandler = (message: MessageEvent) => {
			const eventName = message?.data?.event;
			const payload = message.data?.payload;
			switch (eventName) {
				// Recieve message from GIC anywhere component for current site selection since it is in an inner iframe.
				case JIRA_EMBED_ISSUE_CREATE_SITE_SELECTED:
					if (payload?.selectedCloudId && payload?.selectedCloudId !== '') {
						// reset currentProjectSelection on site change since projects are tied per site
						if (currentSiteSelection && payload?.selectedCloudId !== currentSiteSelection) {
							void setCurrentProjectSelection(undefined);
						}
						void setCurrentSiteSelection(payload.selectedCloudId);
					}
					break;
				case JIRA_EMBED_ISSUE_CREATE_PROJECT_SELECTED:
					const project: JiraProjectSelectionPayload = {
						selectedProjectType: payload?.selectedProjectType,
						selectedProjectId: payload?.selectedProjectId,
						selectedProjectKey: payload?.selectedProjectKey,
					};
					void setCurrentProjectSelection(project);
					break;
				case JIRA_EMBED_ISSUE_CREATE_READY:
					void setIsGicIframeReady(true);
					break;
				case JIRA_EMBED_ISSUE_CREATE_CLOSE:
					void setIsGicIframeReady(false);
					break;
				default:
				// We don't handle other events
			}
		};

		window.addEventListener('message', messageHandler);

		return () => {
			window.removeEventListener('message', messageHandler);
		};
	}, [
		setCurrentSiteSelection,
		setCurrentProjectSelection,
		currentSiteSelection,
		setAiIssueCreationEnablement,
		setIsGicIframeReady,
	]);
};
