export interface ConvoAIStreamingHeaders {
	'content-type'?: string;
	/*
	 * x-experience-id is required header for the convo ai service
	 * it should be defined here for your feature
	 *	https://bitbucket.org/atlassian/assistance-service/src/b2f20cfb1bfe042a2e120f14e601bc52d7c3331d/src/schema/base.py#lines-51
	 */
	'x-experience-id': string;
	/*
	 * x-product is required header for the assistance-service
	 * it should be defined here for your feature
	 *  https://bitbucket.org/atlassian/assistance-service/src/b2f20cfb1bfe042a2e120f14e601bc52d7c3331d/src/schema/base.py#lines-25
	 */
	'x-product': string;
	/**
	 * x-issynthetic-traffic is an optional header for assistance-service
	 * to determine if reqest is synthetic or not. by not passing it in,
	 * it will mean that your synthetic requests will be logged as "real" experiences in SFX
	 * Defaults to false.
	 * https://bitbucket.org/atlassian/assistance-service/src/209f97626770dfe09d523ac8605df64353c09a67/src/schema/execution_context.py?at=master#execution_context.py-208,369,397
	 *
	 */
	'x-issynthetic-traffic'?: string;
}

export interface ConvoAIStreamingRequest<TConvoAIInputContext = object> {
	ai_feature_input: TConvoAIInputContext;
}

export enum ConvoAIErrorMessage {
	'RATE_LIMIT' = 'RATE_LIMIT',
	'ANSWER_UNAVAILABLE_FOR_CONTENT' = 'ANSWER_UNAVAILABLE_FOR_CONTENT',
	'ACCEPTABLE_USE_VIOLATIONS' = 'ACCEPTABLE_USE_VIOLATIONS',
	'NOT_ENOUGH_CONTENT_ERROR' = 'NOT_ENOUGH_CONTENT_ERROR',
	'NOT_FOUND' = 'NOT_FOUND',
	'INTERNAL_SERVER_ERROR' = 'INTERNAL_SERVER_ERROR',
	'BAD_REQUEST' = 'BAD_REQUEST',
	'UNEXPECTED' = 'UNEXPECTED',
	'TIMEOUT' = 'TIMEOUT',
}

export const CONVOAI_DEFAULT_ERROR_CODE = 0;
