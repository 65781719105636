import { useCallback, useEffect, useState } from 'react';

import type { ConvoAIStreamError } from '@atlassian/ai-agent-streaming';
import {
	type AIEventsInstrumentationConfig,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { useSuggestions } from './controllers/use-suggestions';
import { isWhitelistedSLOError, useStreaming } from './services/use-streaming';
import type { HookProps } from './types';

export const useAiWorkSuggestionStreaming = ({
	analyticsConfig,
	issueConfig,
	onCompletion,
	customExperienceTracker,
	requestHeaderOverrides,
	...streamingProps
}: HookProps) => {
	// **** setup ****
	const {
		updateSuggestions,
		suggestions,
		reset: resetSuggestion,
		hideSuggestion,
	} = useSuggestions();
	const [error, setError] = useState<ConvoAIStreamError | undefined>();

	const analyticsContext: AIEventsInstrumentationConfig = {
		...analyticsConfig,
		proactiveGeneratedAI: 0,
		userGeneratedAI: 1,
		isAIFeature: 1,
	};
	const {
		trackAIInteractionInit,
		trackAIInteractionDismiss,
		trackAIResultView,
		trackAIResultAction,
		trackAIResultError,
		trackAIFeedbackSubmit,
	} = useAIEventsInstrumentation(analyticsContext);

	const onStreamError = useCallback(
		(error: ConvoAIStreamError) => {
			customExperienceTracker?.fail?.(error);
			trackAIResultError(
				{
					aiErrorCode: error.message.status_code,
					aiErrorMessage: error.message.message_template,
				},
				!isWhitelistedSLOError(error)
					? {
							attributes: {
								aiResultErrorCategory: 'server-error',
							},
						}
					: undefined,
			);
			setError(error);
		},
		[trackAIResultError, customExperienceTracker],
	);

	const onStreamInit = useCallback(
		(retryCount: number) => {
			resetSuggestion();
			setError(undefined);

			customExperienceTracker?.start?.();
			trackAIInteractionInit({
				attributes: {
					totalRetries: retryCount,
				},
			});
		},
		[trackAIInteractionInit, resetSuggestion, customExperienceTracker],
	);

	const onStreamAborted = useCallback(() => {
		customExperienceTracker?.abort?.();
		trackAIInteractionDismiss();
	}, [trackAIInteractionDismiss, customExperienceTracker]);

	const {
		isStreaming,
		isInitiating: isInitiatingStreaming,
		reset: resetStreaming,
		refetch,
		getIsStreamingRef,
	} = useStreaming({
		analyticsContext,
		streamingProps,
		issueConfig,
		requestHeaderOverrides,
		onSuggestionStreamUpdated: updateSuggestions,
		onStreamError,
		onStreamInit,
		onStreamAbortedByInitNew: onStreamAborted,
	});

	// **** callbacks ****
	const terminate = useCallback(() => {
		if (isStreaming) {
			// stream aborted by terminating service
			onStreamAborted();
		}
		resetSuggestion();
		resetStreaming();
		setError(undefined);
		// leave for side effect to invoke `onCompletion`
	}, [onStreamAborted, isStreaming, resetSuggestion, resetStreaming]);

	const hideSuggestions = useCallback(
		(ids: Array<string>, action?: string) => {
			if (action) {
				ids.forEach((_) => trackAIResultAction(action));
			}
			ids.forEach((id) => hideSuggestion(id));
		},
		[trackAIResultAction, hideSuggestion],
	);

	// **** event handlers ****
	const onAIResultView = useCallback(() => {
		customExperienceTracker?.succeed?.();
		trackAIResultView({ attributes: { suggestedNumber: suggestions.length } });
	}, [trackAIResultView, suggestions.length, customExperienceTracker]);

	// **** side effects ****
	useEffect(() => {
		// work is considered done, if
		// - no suggestions are available
		// - no error exist
		// - no streaming is active
		if (!isInitiatingStreaming && !isStreaming && !error && suggestions.length === 0) {
			onCompletion();
		}
	}, [error, suggestions, isStreaming, onCompletion, isInitiatingStreaming]);

	// report aborted on unmount if still streaming
	useEffect(
		() => () => {
			if (getIsStreamingRef.current()) {
				onStreamAborted();
			}
		},
		[onStreamAborted, getIsStreamingRef],
	);

	return {
		suggestions,
		isStreaming,
		error,

		// event handlers
		onAIResultView,
		onAIFeedbackSubmit: trackAIFeedbackSubmit,

		// callbacks
		refetch,
		hideSuggestions,
		terminate,
	};
};
