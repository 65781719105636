import { useEffect, useMemo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';
import { type AnalyticsAttributes } from '@atlassian/analytics-bridge';
import { ExperienceTracker } from '@atlassian/experience-tracker';

/**
 * @deprecated Use `experienceTracker` instead.
 * @example
 * ```tsx
 * const experienceTracker = useContext(ExperienceTrackerContext);
 * experienceTracker.start({})
 * ```
 */
export const useAiIssueCreateAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const actions = useMemo(() => {
		return {
			fireTrack: (name: string, attributes?: AnalyticsAttributes) => {
				const source = 'aiIssueCreate';
				const [actionSubject, action] = name.split(' ');
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: { source, action, actionSubject, attributes },
				}).fire();
			},
		};
	}, [createAnalyticsEvent]);
	return actions;
};

export const useAiIssueCreateExperienceTracker = () => {
	const experienceTracker = useMemo(() => new ExperienceTracker(), []);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (fg('crocs-ai-issue-create-user-experience-tracker')) {
			const unsubscribe = experienceTracker.subscribe((event) => {
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						source: 'ui',
						action: event.action,
						actionSubject: 'ui',
						attributes: {
							task: event.name,
							taskId: event.id,
							startTime: event.startTime,
							...('duration' in event && {
								duration: event.duration,
							}),
							...event.attributes,
						},
					},
				}).fire();
			});
			return () => unsubscribe();
		}
	}, [experienceTracker, createAnalyticsEvent]);

	return experienceTracker;
};
