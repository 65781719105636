import type { DocNode } from '@atlaskit/adf-schema';

import type { IssueType } from '../providers/BulkCreateContextProvider';


export type FetchProjectDataProps = {
  /**
   * Jira Cloud ID for the site we want to get available projects for
   */
  cloudId?: string;
  /**
   * Filter the results using a literal string. Projects with a matching key or name are returned (case insensitive).
   */
  query?: string;
  /**
   * Jira Project ID for the site we want to get available projects for
   * Example: ['10000', '10001', '10002']
   */
  projectIds?: string[];
  onComplete?: (projects: JiraProjects | undefined, recentProjects: JiraProjects | undefined) => void;
};

export type FetchIssueTypeDataProps = {
  /**
   * Jira Cloud ID for the site we want to get available projects for
   */
  cloudId?: string;
  /**
   * Jira Project ID for the site we want to get available issue types for
   */
  projectId?: string;
  onComplete?: (issueTypes: JiraIssueTypes | undefined) => void;
};

export type CreateBulkIssueProps = {
  limit: number;
  cloudId: string;
  projectId: string;
}

export type CreateIssueProps = {
  cloudId: string;
  issueBody: CreateIssueBody;
}

export type CreateBulkIssuesBody = {
  issueUpdates: CreateIssueBody[];
}

export type CreateBulkIssuesProps = {
  cloudId: string;
  issueBody: CreateBulkIssuesBody;
}

export type UseCreateIssueProps = {
  cloudId: string;
  issueId: string;
}

type CreateIssueBodyIssueType = {
  id: string;
}

type CreateIssueBodyProjectType = {
  id: string;
}

// Expand on this to support more value types
export type SupportedValueType = string;
export type CreateIssueBodyFields = {
  description?: DocNode;
  issuetype: CreateIssueBodyIssueType;
  project: CreateIssueBodyProjectType;
  summary: string;
  [key: string]: SupportedValueType | DocNode | CreateIssueBodyProjectType | CreateIssueBodyIssueType | undefined;
}

export type CreateIssueBody = {
  fields: CreateIssueBodyFields;
}

type CreateIssueResponseTransitionErrorCollection = {
  errorMessages: [];
  errors: {};
}

type CreateIssueResponseTransition = {
  status: number;
  errorCollection: CreateIssueResponseTransitionErrorCollection
}

export type CreateIssueResponse = {
	id: string;
	key: string;
	self: string;
	transition: CreateIssueResponseTransition;
  summary: string;
	rowIndex?: number;
}

export type CreateAllIssueResponse = {
  createdIssueIDs: string[];
  createdIssues: CreateIssueResponse[],
  failedIssueIDs: string[];
}

type CreateBulkIssuesElementError = {
  errorMessages: string[];
  errors: any;
  status?: number;
}

type CreateBulkIssuesError = {
  elementErrors: CreateBulkIssuesElementError
  failedElementNumber: number;
  status: number;
}

export type CreateBulkIssuesResponse = {
  errors: CreateBulkIssuesError[];
  issues: CreateIssueResponse[];
}

type AvatarUrls = {
  '16x16': string;
  '24x24': string;
  '32x32': string;
  '48x48': string;
};

export enum JiraProjectType {
  JiraCore = 'jira-core',
  JiraServiceDesk = 'jira-servicedesk',
  JiraCustomerService = 'jira-customer-service',
  JiraSoftware = 'jira-software',
  JiraProductDiscovery = 'jira-product-discovery',
}
type MessageProjectTypeToApiProjectType = {
  [key: string]: JiraProjectType
}

export const MESSAGE_PROJECT_TYPE_TO_API_PROJECT_TYPE: MessageProjectTypeToApiProjectType = {
  'service_desk': JiraProjectType.JiraServiceDesk,
  'software': JiraProjectType.JiraSoftware,
  'customer_service': JiraProjectType.JiraCustomerService,
  'business': JiraProjectType.JiraCore,
  'product_discovery': JiraProjectType.JiraProductDiscovery
}

export type JiraProject = {
  entityId: string;
  expand: string;
  isPrivate: boolean;
  projectTypeKey: string;
  self: string;
  simplified: boolean;
  style: string;
  uuid: string;
  name: string;
  key: string;
  id: string;
  avatarUrls: AvatarUrls;
};

export type JiraProjects = JiraProject[];

export type JiraIssue = {
  description: string;
  iconUrl: string;
  id: string;
  name: string;
  self: string;
  subtask: boolean;
};

export type JiraIssueTypes = IssueType[];

export interface FieldMetadata {
  fieldId: string;
  hasDefaultValue: boolean;
  key: string;
  name: string;
  operations: string[];
  required: boolean;
}

export interface JiraIssueMetadataResponse {
  fields: FieldMetadata[];
  maxResults: number;
  startAt: number;
  total: number;
}

export interface JiraAiEnabledResponse {
  isEnabled: boolean
}
