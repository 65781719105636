import { parse } from 'partial-json';

import { type ConvoAIStreamingConfig } from '../../models/ConvoAIStreamingConfig';
import {
	ConvoAIResponseState,
	type ConvoAIStreamingState,
} from '../../models/ConvoAIStreamingState';

export function parsePartialConvoAIJsonIfNeeded<TContentResponse>(
	state: ConvoAIStreamingState,
	config: ConvoAIStreamingConfig,
): ConvoAIStreamingState {
	if (
		state.responseState !== ConvoAIResponseState.Initialization &&
		config.enablePartialJson &&
		state.content
	) {
		try {
			const parsedContent = parse(state.content);
			return {
				...state,
				content: parsedContent,
			};
		} catch (error) {}
	}
	return state as ConvoAIStreamingState;
}
