import React from 'react';

import { IssueCreateWarningDialogContainer } from './IssueCreateWarningDialogContextProvider';
import { IssueCreateSidePanelContainer } from './IssueCreateSidePanelContextProvider';

type SingleIssueCreateProvidersWrapperProps = {
	children?: React.ReactNode;
};
export const SingleIssueCreateProvidersWrapper: React.FC<
	SingleIssueCreateProvidersWrapperProps
> = ({ children }) => {
	return (
		<IssueCreateSidePanelContainer isGlobal>
			<IssueCreateWarningDialogContainer>{children}</IssueCreateWarningDialogContainer>
		</IssueCreateSidePanelContainer>
	);
};
