import { Lens } from 'monocle-ts';

import {
	type ConvoAIStreamingConfig,
	type UserConvoAIStreamingConfig,
} from '../../models/ConvoAIStreamingConfig';

import { BASE_STREAM_URL } from './convoAIConstants';
import { type ConvoAIStreamingHeaders } from './ConvoAIStreamingModels';

const baseUrl = Lens.fromNullableProp<UserConvoAIStreamingConfig>()('baseUrl', BASE_STREAM_URL);
const enablePartialJson = Lens.fromNullableProp<UserConvoAIStreamingConfig>()(
	'enablePartialJson',
	false,
);
const enableFollowUp = Lens.fromNullableProp<UserConvoAIStreamingConfig>()('enableFollowUp', false);
const enableLocalVerboseLogs = Lens.fromNullableProp<UserConvoAIStreamingConfig>()(
	'enableLocalVerboseLogs',
	false,
);
const skip = Lens.fromNullableProp<UserConvoAIStreamingConfig>()('skip', false);

const contentType = Lens.fromNullableProp<ConvoAIStreamingHeaders>()(
	'content-type',
	'application/json;charset=UTF-8',
);

export const getDefaultConvoAIConfigWithUserSettings = (
	config: UserConvoAIStreamingConfig,
): ConvoAIStreamingConfig => ({
	baseUrl: baseUrl.get(config),
	apiPath: config.apiPath,
	enablePartialJson: enablePartialJson.get(config),
	enableFollowUp: enableFollowUp.get(config),
	enableLocalVerboseLogs: enableLocalVerboseLogs.get(config),
	timeoutDelay: config.timeoutDelay,
	skip: skip.get(config),
	headers: {
		'content-type': contentType.get(config.headers),
		'x-product': config.headers['x-product'],
		'x-experience-id': config.headers['x-experience-id'],
		'x-issynthetic-traffic': config.headers['x-issynthetic-traffic'] ?? 'false',
	},
});
