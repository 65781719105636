import { type UserConvoAIStreamingConfig } from '../../models/ConvoAIStreamingConfig';

import { type ConvoAIStreamingRequest } from './ConvoAIStreamingModels';

export type ConvoAIRequestConfig = {
	requestInitParams: RequestInit;
	url: string;
};

export const createConvoAIRequestConfig = (
	request: ConvoAIStreamingRequest,
	config: UserConvoAIStreamingConfig,
): ConvoAIRequestConfig => {
	const requestInitParams: RequestInit = {
		method: 'POST',
		headers: new Headers({
			...config.headers,
		}),
		body: JSON.stringify(request),
	};

	return {
		requestInitParams,
		url: `${config.baseUrl}/${config.apiPath}`,
	};
};
