import type { ConvoAIErrorMessage } from '../api/ConvoAI/ConvoAIStreamingModels';
import type {
	AnswerPartResponseMessage,
	ChannelIdResponseMessage,
	ErrorResponseMessage,
	FinalResponseMessage,
	FollowUpObject,
	FollowUpResponseMessage,
	StreamError,
} from '../api/ConvoAI/ConvoAIStreamMessages';

export enum ConvoAIResponseState {
	Initialization = 'Initialization',
	ChannelId = 'ChannelId',
	AnswerPart = 'AnswerPart',
	FinalResponse = 'FinalResponse',
	FollowUp = 'FollowUp',
	Error = 'Error',
}

export type ConvoAIStreamingState<
	TFinalResponse extends FinalResponseMessage = FinalResponseMessage<string>,
	TError extends ErrorResponseMessage = ErrorResponseMessage<`${ConvoAIErrorMessage}`>,
	TFollowUp extends FollowUpResponseMessage = FollowUpResponseMessage<FollowUpObject[]>,
	TAnswerPart extends AnswerPartResponseMessage = TFinalResponse,
	TChannelId extends ChannelIdResponseMessage = TFinalResponse,
> =
	| {
			responseState: ConvoAIResponseState.Initialization;
			content: null;
			answerChunkIndex?: number;
			error?: StreamError<TError['message_template']>;
			followUpContent?: TFollowUp['follow_ups'];
	  }
	| {
			responseState: ConvoAIResponseState.ChannelId;
			content: TChannelId['content'];
			answerChunkIndex?: number;
			error?: StreamError<TError['message_template']>;
			followUpContent?: TFollowUp['follow_ups'];
	  }
	| {
			responseState: ConvoAIResponseState.AnswerPart;
			content: TAnswerPart['content'];
			answerChunkIndex: number;
			error?: StreamError<TError['message_template']>;
			followUpContent?: TFollowUp['follow_ups'];
	  }
	| {
			responseState: ConvoAIResponseState.FinalResponse;
			content: TFinalResponse['content'];
			answerChunkIndex?: number;
			error?: StreamError<TError['message_template']>;
			followUpContent?: TFollowUp['follow_ups'];
	  }
	| {
			responseState: ConvoAIResponseState.FollowUp;
			content: TFinalResponse['content'] | null;
			followUpContent: TFollowUp['follow_ups'];
			answerChunkIndex?: number;
			error?: StreamError<TError['message_template']>;
	  }
	| {
			responseState: ConvoAIResponseState.Error;
			content: TFinalResponse['content'] | null;
			answerChunkIndex?: number;
			followUpContent?: TFollowUp['follow_ups'];
			error: StreamError<TError['message_template']>;
	  };

export function getDefaultConvoAIStreamingState<
	TFinalResponse extends FinalResponseMessage = FinalResponseMessage<string>,
	TError extends ErrorResponseMessage = ErrorResponseMessage<`${ConvoAIErrorMessage}`>,
	TFollowUp extends FollowUpResponseMessage = FollowUpResponseMessage<FollowUpObject[]>,
	TAnswerPart extends AnswerPartResponseMessage = TFinalResponse,
	TChannelId extends ChannelIdResponseMessage = TFinalResponse,
>(): ConvoAIStreamingState<TFinalResponse, TError, TFollowUp, TAnswerPart, TChannelId> {
	return {
		content: null,
		responseState: ConvoAIResponseState.Initialization,
	};
}
