import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { ExperienceTrackerContext } from '@atlassian/experience-tracker';

import {
	AssistanceServiceMessageTemplate,
	type AssistanceServiceMessageTemplateValues,
	type SuggestedIssue,
	SuggestionResult,
} from '../../common/types';
import { useAiIssueCreateAnalytics } from '../ai-issue-create-analytics';

import {
	ConvoAiError,
	fetchAiSuggestedFields,
	FetchSuggestionError,
	isWhitelistedStatusCode,
} from './utils';

type UseFetchAiSuggestedFields = {
	cloudId: string;
	/**
	 * Confluence page in markdown plus format
	 */
	confluencePage: string;
	/**
	 * The snippet of text that is highlighted in markdown plus format
	 */
	highlightedText: string;
	/**
	 * The product to be used in the request to Assistance Service. By default it will be used for the X-Product and request body application type.
	 */
	product: string;
	/**
	 * The X-Experience-Id to be used in the request to Assistance Service
	 */
	experienceId: string;
	/**
	 * Callback fired when state is changed
	 * @param newState - the new state
	 */
	onStateChange: (newState: FetchState) => void;
	/**
	 * The override X-Product to be used in the request to Assistance Service.
	 * This is specifically used in AIWC between Jira and Confluence when Confluence has AI toggled off but Jira has it on.
	 * In this case we can use Jira's enablement to promote more AI usage across products.
	 */
	overrideXProduct?: string;
};

type AbortState = { state: 'abort'; fields: undefined };
type LoadingState = { state: 'loading'; fields: undefined };
type ErrorState = {
	state: 'error';
	error: Error | unknown;
	message_template: AssistanceServiceMessageTemplateValues;
	fields: undefined;
};
type SuccessState = { state: 'idle'; fields: SuggestedIssue };
type FetchState = AbortState | LoadingState | ErrorState | SuccessState;

const experienceName = 'aiIssueCreateFetchSuggestedFieldsRequest';

const TOKEN_LIMITS = 18500;
const CHARS_LIMITS = TOKEN_LIMITS * 4; // We use 1 token ~= 4 chars in English

export const useFetchAiSuggestedFields = ({
	cloudId,
	confluencePage,
	highlightedText,
	product,
	experienceId,
	onStateChange,
	overrideXProduct,
}: UseFetchAiSuggestedFields): FetchState & { trigger: () => Promise<void> } => {
	const { fireTrack } = useAiIssueCreateAnalytics();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const [fetchState, setFetchState] = useState<FetchState>({ state: 'loading', fields: undefined });

	const abortControllerRef = useRef<AbortController | null>(null);

	const fetchAiFields = useCallback(async () => {
		if (!cloudId || !confluencePage) {
			return;
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();
		const currentAbortController = abortControllerRef.current;

		setFetchState({ state: 'loading', fields: undefined });

		const contentLength = confluencePage.length + highlightedText.length;
		try {
			// CRCS-1048: remove once new events have enough data for validation and swap
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest start');
			if (fg('crocs-ai-issue-create-user-experience-tracker')) {
				experienceTracker.start({ name: experienceName, id: experienceName });
			}

			if (fg('set_an_upper_threshold_for_token_limits')) {
				if (contentLength > CHARS_LIMITS) {
					const message_template =
						AssistanceServiceMessageTemplate.FE_REQUEST_EXCEEDING_TOKEN_LENGTH;
					const errorMessage = 'Exceeds token length limit';
					const error = new FetchSuggestionError(errorMessage, message_template);

					setFetchState({ state: 'error', error, message_template, fields: undefined });
					if (fg('crocs-ai-issue-create-user-experience-tracker')) {
						experienceTracker.fail({
							name: experienceName,
							error,
							attributes: { errorMessage, message_template, shouldSlo: false, contentLength },
						});
					}
					return;
				}
			}

			const result = await fetchAiSuggestedFields({
				cloudId,
				confluencePage,
				experienceId,
				highlightedText,
				product,
				signal: currentAbortController.signal,
				overrideXProduct,
			});

			// CRCS-1048: remove once new events have enough data for validation and swap
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest complete', {
				haveResult: !!result,
			});
			if (fg('crocs-ai-issue-create-user-experience-tracker')) {
				if (fg('set_an_upper_threshold_for_token_limits')) {
					experienceTracker.succeed({
						name: experienceName,
						attributes: {
							haveResult: !!result,
							contentLength,
							totalUsage: (result as SuggestionResult | undefined)?.usage?.total_tokens,
							// Remove SuggestedIssue type when clean up feature flag set_an_upper_threshold_for_token_limits
						},
					});
				} else {
					experienceTracker.succeed({
						name: experienceName,
						attributes: { haveResult: !!result },
					});
				}
			}

			if (currentAbortController.signal.aborted) {
				return;
			}

			if (result) {
				if (fg('set_an_upper_threshold_for_token_limits')) {
					// Remove "as" assertion when clean up feature flag set_an_upper_threshold_for_token_limits
					setFetchState({ state: 'idle', fields: (result as SuggestionResult).suggestion });
				} else {
					// Remove "as" assertion when clean up feature flag set_an_upper_threshold_for_token_limits
					setFetchState({ state: 'idle', fields: result as SuggestedIssue });
				}
			} else {
				if (fg('set_an_upper_threshold_for_token_limits')) {
					const message_template = AssistanceServiceMessageTemplate.UNKNOWN_ERROR;
					setFetchState({
						state: 'error',
						error: new FetchSuggestionError(
							'Error on suggesting AI Issue Create from Assistance Service',
							message_template,
						),
						message_template,
						fields: undefined,
					});
				} else {
					setFetchState({
						state: 'error',
						error: new Error('Error on suggesting AI Issue Create from Assistance Service'),
						message_template: AssistanceServiceMessageTemplate.UNKNOWN_ERROR,
						fields: undefined,
					});
				}
			}
		} catch (e: Error | ConvoAiError | unknown) {
			let statusCode: number | undefined;
			let errorMessage: string | undefined;
			let message_template: AssistanceServiceMessageTemplateValues =
				AssistanceServiceMessageTemplate.UNKNOWN_ERROR; // default to unknown
			let shouldSlo = true;

			if (e instanceof ConvoAiError) {
				statusCode = e.statusCode;
				errorMessage = e.errorMessage;
				message_template = e.message_template;
				shouldSlo = fg('jira_ai_issue_create_status_code_whitelist')
					? !isWhitelistedStatusCode(statusCode)
					: e.shouldSlo;
			} else if (e instanceof Error) {
				errorMessage = e.message;
			} else {
				errorMessage = `Error occurred when requesting to ${experienceId} agent`;
			}

			if (currentAbortController.signal.aborted) {
				setFetchState({ state: 'abort', fields: undefined });
				return;
			}

			// CRCS-1048: remove once new events have enough data for validation and swap
			fireTrack('aiIssueCreateFetchSuggestedFieldsRequest fail', {
				errorMessage,
				statusCode,
				message_template,
				shouldSlo,
			});
			if (fg('crocs-ai-issue-create-user-experience-tracker')) {
				experienceTracker.fail({
					name: experienceName,
					error: e instanceof Error ? e : new Error(errorMessage),
					attributes: { errorMessage, statusCode, message_template, shouldSlo, contentLength },
				});
			}

			setFetchState({ state: 'error', error: e, message_template, fields: undefined });
		}
	}, [
		cloudId,
		confluencePage,
		experienceId,
		fireTrack,
		highlightedText,
		product,
		experienceTracker,
		overrideXProduct,
	]);

	useEffect(() => {
		fetchAiFields();

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [fetchAiFields]);

	useEffect(() => {
		if (onStateChange) {
			onStateChange(fetchState);
		}
	}, [fetchState.state, fetchState.fields, onStateChange, fetchState]);

	const trigger = useCallback(() => {
		return fetchAiFields();
	}, [fetchAiFields]);

	return { ...fetchState, trigger };
};
