import { match, P } from 'ts-pattern';

import { CONVOAI_DEFAULT_ERROR_CODE, ConvoAIErrorMessage } from './ConvoAIStreamingModels';
import { type StreamError, type StreamMessage } from './ConvoAIStreamMessages';

export async function* readStream(response: Response): AsyncGenerator<StreamMessage, void> {
	// covered HTTP errors only from this doc
	// https://hello.atlassian.net/wiki/spaces/CONVAI/pages/3420210503/Error+message+formats+-+assistance-service
	// Kept for extra error handling security as we migrate to convo-ai from agent service
	const possibleHTTPError = await match(response)
		.returnType<Promise<StreamError | null>>()
		.with({ status: 201 }, async () => {
			const responseText = await response.text();
			return {
				type: 'ERROR',
				message: {
					content: `[HTTP Error]: No answer was generated by Open AI. Raw error details: ${responseText}. statusCode: ${response.status}`,
					message_template: ConvoAIErrorMessage.BAD_REQUEST,
					status_code: response.status,
				},
			};
		})
		.with({ status: 400 }, async () => {
			const responseText = await response.text();
			return {
				type: 'ERROR',
				message: {
					content: `[HTTP Error]: BAD_REQUEST or NO_AGENT or EXCEEDING_CONTEXT_LENGTH_ERROR. Raw error details: ${responseText}. statusCode: ${response.status}`,
					message_template: ConvoAIErrorMessage.BAD_REQUEST,
					status_code: response.status,
				},
			};
		})
		.with({ status: 403 }, async () => {
			const responseText = await response.text();
			return {
				type: 'ERROR',
				message: {
					content: `[HTTP Error]: FEATURE_DISABLED_ON_SITE or FEATURE_DISABLED or HIPAA_CONTENT_DETECTED. Raw error details: ${responseText}. statusCode: ${response.status}`,
					message_template: ConvoAIErrorMessage.BAD_REQUEST,
					status_code: response.status,
				},
			};
		})
		.with({ status: 429 }, async () => {
			const responseText = await response.text();
			return {
				type: 'ERROR',
				message: {
					content: `[HTTP Error]: RATE_LIMIT or OPENAI_RATE_LIMIT_USER_ABUSE. Raw error details: ${responseText}. statusCode: ${response.status}`,
					message_template: ConvoAIErrorMessage.RATE_LIMIT,
					status_code: response.status,
				},
			};
		})
		.with({ status: 500 }, async () => {
			const responseText = await response.text();
			return {
				type: 'ERROR',
				message: {
					content: `[HTTP Error]: INTERNAL_SERVER_ERROR. Raw error details: ${responseText}. statusCode: ${response.status}`,
					message_template: ConvoAIErrorMessage.UNEXPECTED,
					status_code: response.status,
				},
			};
		})
		.with({ status: P.not(200) }, async () => {
			const responseText = await response.text();
			return {
				type: 'ERROR',
				message: {
					content: `[HTTP Error]: OTHER_SERVER_ERROR. Raw error details: ${responseText}. statusCode: ${response.status}`,
					message_template: ConvoAIErrorMessage.UNEXPECTED,
					status_code: response.status,
				},
			};
		})
		.otherwise(() => Promise.resolve(null));

	if (possibleHTTPError) {
		yield possibleHTTPError;
		return;
	}

	if (!response.body) {
		const responseText = await response.text();
		yield {
			type: 'ERROR',
			message: {
				content: `[Error]: response.body is missing. Raw error details: ${responseText}. statusCode: ${response.status}`,
				message_template: ConvoAIErrorMessage.UNEXPECTED,
				status_code: response.status,
			},
		};
		return;
	}

	try {
		const reader = response.body.getReader();
		const decoder = new TextDecoder('utf-8');
		let buffer = '';
		let done = false;

		while (!done) {
			const { value, done: doneReading } = await reader.read();
			const decodedValue = decoder.decode(value);
			done = doneReading;

			buffer = `${buffer}${decodedValue}`;
			// Split the buffer by line breaks
			const lines = buffer.split('\n');
			// Process all complete lines, except for the last one (which might be incomplete)
			while (lines.length > 1) {
				const line = lines.shift()!;
				const parsedData = JSON.parse(line);

				yield parsedData;
			}
			// Keep the last (potentially incomplete) line in the buffer
			buffer = lines[0];
		}

		return;
	} catch (parsingError: unknown) {
		let errorText = '';
		if (typeof parsingError === 'string') {
			errorText = parsingError.toUpperCase();
		} else if (parsingError instanceof Error) {
			errorText = parsingError.message;
		}

		yield {
			type: 'ERROR',
			message: {
				content: `[Stream Error]: ${errorText}. locked status: ${response.body.locked}. statusCode: ${response.status}`,
				message_template: ConvoAIErrorMessage.UNEXPECTED,
				status_code: CONVOAI_DEFAULT_ERROR_CODE,
			},
		};
		return;
	}
}
